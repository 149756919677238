import React from 'react'

function Home() {
  return (
    <div className='home'>
      <img src="/assets/images/logo.png" className='logo-home'   alt="Job Flow Connect" />
      <div>
      <h2>Welcome to </h2> <br />
      <h1>Job Flow Connect</h1><br />
      <h3>Find Jobs Easily</h3>
      </div>
    </div>
  )
}

export default Home