import { HttpService } from "core/services/http";


export class BaseHttpService {
    
    static async loadUser(id){
        var service = new HttpService();
        var response = await service.get(`/auth/user/${id}`,null);
        return response;
    }

    static async loadMe(){
        var service = new HttpService();
        var response = await service.get(`/api/profile/`,null);
        return response;
    }

    static async saveUser(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/auth/profiles/${body.id}/`, body);
        }
        else
            response = await service.post('/auth/user/new/', body);

        return await response
    }

    static async loadCountries(){
        var service = new HttpService();
        var response = await service.get(`/base/country/?size=1000000`,null);
        return response;
    }

    static async loadCities(country_id){
        var service = new HttpService();
        var response = await service.get(`/base/country/${country_id}/cities?size=1000000`,null);
        return response;
    }

    static async saveLanguage(body) {
        
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/base/language/${body.id}/`, body);
        }
        else
            response = await service.post('/base/language/', body);

        return await response
    }

    static async loadLanguage(id){
        var service = new HttpService();
        var response = await service.get(`/base/language/${id}`,null);
        return response;
    }

    static async loadLanguages(){
        var service = new HttpService();
        var response = await service.get('/base/language/',null);
        return response;
    }

    static async loadDictionaryLanguages(){
        var service = new HttpService();
        var response = await service.get('/api/dictionarylanguage/',null);
        return response;
    }

    static async loadCity(id){
        var service = new HttpService();
        var response = await service.get(`/base/city/${id}`,null);
        return response;
    }

    static async saveCity(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/base/city/${body.id}`, body);
        }
        else
            response = await service.post('/base/city/', body);

        return await response
    }

    static async deleteItem(url){
        var service = new HttpService();
        var response = await service.delete(`${url}`);
        return response;
    }

    ////////////////////////////////////////////////////////////////

    static async loadGalleries(page){
        var service = new HttpService();
        var response = await service.get(`/postapi/gallery/?page=${page}&type="academy_course"`,null);
        return response;
    }

    static async loadGallery(id){
        var service = new HttpService();
        var response = await service.get(`/postapi/gallery/${id}`,null);
        return response;
    }

    static async SaveGallery(title, file) {
        const formData = new FormData();
        formData.append("title", title);
        formData.append("image", file, file.name);
        
        var service = new HttpService();
        var response = await service.post(`/postapi/gallery/`,formData,true);
      
        return response;
      }


    ////////////////////////////////////////////////////////////////


    
    static async loadSettings(){
        var service = new HttpService();
        var response = await service.get('/base/setting/',null);
        return response;
    }

    static async saveTranslation(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/base/translate/${body.id}`, body);
        }
        else
            response = await service.post('/base/translate/', body);

        return await response
    }

    static async loadTranslation(id){
        var service = new HttpService();
        var response = await service.get(`/base/translate/${id}`,null);
        return response;
    }

    static async loadTranslations(){
        
        var service = new HttpService();
        var response = await service.get(`/base/translate/?size=10000`,null);
        return response;
    }

    static async searchTranslations(query=""){
        
        var service = new HttpService();
        var response = await service.get(`/base/translate/`,null); //?query=${query}
        return response;
    }

    static async loadDictionaryEntries(start_letter,search_query=""){
        var query= `?size=100`
        if(start_letter){
            query += `&start_letter=${start_letter}`
        }

        if(search_query){
            query += `&query=${search_query}`
        }


        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/${query}`,null);
        return response;
    }

    static async searchDictionaryEntries(query=""){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/?query=${query}`,null);
        return response;
    }

    static async loadDictionaryEntryCharacters(){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentrycharacters/`,null);
        return response;
    }
    

    static async loadDictionaryEntry(id){
        var service = new HttpService();
        var response = await service.get(`/api/dictionaryentry/${id}`,null);
        return response;
    }

    static async saveDictionaryEntry(body) {
        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/api/dictionaryentry/${body.id}`, body);
        }
        else
            response = await service.post('/api/dictionaryentry/', body);

        return await response
    }

    
    static async saveSetting(body) {
        const formData = new FormData();

        formData.append("site_title", body.site_title);
        formData.append("site_description", body.site_description);
        formData.append("about_text", body.about_text);
        formData.append("contact_text", body.contact_text);
        formData.append("privacy_text", body.privacy_text);
        formData.append("terms_text", body.terms_text);
        formData.append("cookie_text", body.cookie_text);
        
        if (body.file) formData.append("logo", body.file, body.file.name);

        var service = new HttpService();
        var response;


        if (body.id) {
            response = await service.put(`/base/setting/${body.id}`, formData, true);
        }
        else
            response = await service.post('/base/setting/', formData, true);

        return await response
    }

   
}

