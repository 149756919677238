import React from 'react'
import JobList from '../components/JobList'
import AdminLayout from 'cms/core/shared/AdminLayout'

function JobScreen() {
  return (
    <AdminLayout>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Jobs</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/cms">Home</a></li>
                <li className="breadcrumb-item active">Jobs</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
              <JobList />
        </div>
      </section>
    </AdminLayout>
  )
}

export default JobScreen