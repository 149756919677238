import { HttpService } from "core/services/http";

export class JobHttpService {

    static async saveBenefit(body) {

        var service = new HttpService();
        var response;

        if (body.id) {
            response = await service.put(`/job/benefit/${body.id}`, body);
        }
        else
            response = await service.post('/job/benefit/', body);

        return await response
    }

    static async loadBenefit(id) {
        var service = new HttpService();
        var response = await service.get(`/job/benefit/${id}`, null);
        return response;
    }

    static async loadBenefits(page=1) {
        var service = new HttpService();
        var response = await service.get('/job/benefit/', null);
        return response;
    }

    static async loadJobs(page=1) {
        var service = new HttpService();
        var response = await service.get('/job/', null);
        return response;
    }
   
}

