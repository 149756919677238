import React from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import CancelIcon from '@mui/icons-material/Close';

const SearchQuery = ({ onQuery, query }) => {
  const [inputValue, setInputValue] = React.useState(query);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onQuery(inputValue);
  };

  const handleClearSearch = () => {
    setInputValue("");
    onQuery("");
  };

  return (
    <form onSubmit={handleSubmit} style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        variant="outlined"
        size="small"
        label="Search..."
        value={inputValue}
        onChange={handleInputChange}
        InputProps={{
          endAdornment: inputValue ? (
            <IconButton onClick={handleClearSearch} aria-label="clear search">
              <CancelIcon />
            </IconButton>
          ) : <IconButton type="submit" aria-label="search">
            <SearchIcon />
          </IconButton>,
        }}
        style={{ marginRight: '8px', flexGrow: 1 }}
      />

    </form>
  );
};

export default SearchQuery;
