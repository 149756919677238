import React from 'react'
import Footer from './Footer'
import MainMenu from './MainMenu'
import Navbar from './Navbar'
import 'admin-lte/dist/js/adminlte.min.js';

function AdminLayout({children}){
  return (
    <div className="wrapper">
          <Navbar />
          <MainMenu />
          <div className="content-wrapper">
            {children}
          </div>
          <Footer />
        </div>
  )
}

export default AdminLayout