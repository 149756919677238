import { HttpService } from 'core/services/http';
import { UserHttpService } from 'core/services/user';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

function LogindPage() {
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const OnSubmit = (data) => {
        if (loading) return;

        setLoading(true);
        var body = {
            "email": data.email,
            "username": data.email,
            "password": data.password
        }

        UserHttpService.login(body).then(response => {
            setLoading(false);
            if (response.role) {
                HttpService.setToken(response.tokens.access)
                HttpService.setUser(response)

                if (response.role == 'admin') {
                    window.location.href = "/cms/dashboard"
                }
                else {
                    window.location.href = "/"
                }
            }
            else {
                console.log(response)
                if (response.detail) {
                    alert(response.detail)
                }
                else if (response.email) {
                    alert(response.email)
                }
                else {
                    alert(response[0])
                }

            }
        });
    };
    return (
        <div className="hold-transition login-page">
            <div className="login-box">
                <div className="login-logo">
                    <a href="/cms"><b>Job Flow</b>Connect</a>
                </div>
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        <form onSubmit={handleSubmit(OnSubmit)}>
                            <div className="input-group mb-3">
                                <input type="email" error={errors.email} {...register("email", { required: true })} className={`form-control  ${errors.email ? "has_error" : ""} `} required placeholder="Email" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password" error={errors.password} {...register("password", { required: true })} required className={`form-control  ${errors.password ? "has_error" : ""} `} placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <div className="icheck-primary">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                </div>
                            </div>
                        </form>

                        {/* <div className="social-auth-links text-center mb-3">
        <p>- OR -</p>
        <a href="#" className="btn btn-block btn-primary">
          <i className="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
        <a href="#" className="btn btn-block btn-danger">
          <i className="fab fa-google-plus mr-2"></i> Sign in using Google+
        </a>
      </div> */}

                        {/* <p className="mb-1">
        <a href="forgot-password.html">I forgot my password</a>
      </p>
      <p className="mb-0">
        <a href="register.html" className="text-center">Register a new membership</a>
      </p> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LogindPage