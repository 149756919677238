import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Button, TextField } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { Edit } from "@mui/icons-material";
import Done from "core/components/shared/Done";
import Error from "core/components/shared/Error";
import LoadingInside from "core/components/shared/LoadingInside";
import { BaseHttpService } from "cms/core/services";
import ValuesWidgets from "cms/components/ValuesWidgets";

function TranslationForm({ id, ...props }) {
  const { register, handleSubmit, setValue, watch, formState: { errors }, } = useForm();
  const [editingItem, setEditingItem] = useState(null);

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const [item_image, setImage] = useState(null);
  const [data, setData] = useState(null);
  const [names, setNames] = useState([]);
  const [namesRefresh, setNamesRefresh] = useState(0);


  const fileRef = useRef()

  useEffect(() => {
    if (id) {
      BaseHttpService.loadTranslation(id).then(item => {
        setData(item)
        onEditing(item)
      })
    }
  }, [])

  const onEditing = (item) => {
    setEditingItem(item)


    for (const key in item) {
      setValue(key, item[key])
    }

    if (item.parent) {
      setValue("parent", item.parent.id)
    }

    setNames(item.values)

    setNamesRefresh(refresh + 1)
    setImage(item.image)
  }

  const saveChanges = (data) => {

    setError(false)
    if (editingItem) data.id = editingItem.id;

    var body = {
      "id": id ? id : data.id,
      "key": data.key,
      "values": names,
    }


    setDone(false)
    setLoading(true)
    BaseHttpService.saveTranslation(body).then(response => {
      setLoading(false)
      setDone(true)
      setError(false)
      props.onEditDone(response);
    }).catch(err => {
      setLoading(false)
      // setError("Something went wrong")
    });
  };

  const handleValue = (value, name) => {
    switch (name) {

      case "status":
        setValue("status", value)
        break;
      case "parent":
        setValue("parent", value)
        break;
      default:
        break;
    }
  }



  return (
    <div>


      <form className="mt-4" onSubmit={handleSubmit(saveChanges)}>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <TextField
                fullWidth
                label="Key"
                value={watch('key') ?? ""}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                {...register("key")}
              />
            </div>
            <hr className="solid" />
            <div className="form-group">
              <ValuesWidgets key={namesRefresh + "nms"} values={names} setValues={(names) => { setNames(names) }} />
            </div>
            <hr className="solid" />
          </div>
        </div>
        <div className="form-group mt-3 text-right mb-1">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            startIcon={editingItem ? <Edit /> : <AddIcon />}
          >
            {editingItem ? "Save Changes" : "Add Translation"}
          </Button>


        </div>
        <div className="form-group">
          {done && <Done />}
          {error && <Error message={error} />}
          {loading && <LoadingInside />}
        </div>
      </form>
    </div>
  );
}

export default TranslationForm;
