import { useQuery, useQueryClient } from "react-query";
import { JobHttpService } from "../service";

/**
 * A custom React hook for fetching and paginating explore posts.
 * @returns {{
 *   data: any,
 *   isLoading: boolean,
 *   isError: boolean,
 * }}
 */
function UseBenefitList() {
    const queryClient = useQueryClient();

    const {
        data,
        isLoading,
        isError,
        refetch,
    } = useQuery(
        ["benefits_list"],
        () => {
            return JobHttpService.loadBenefits()
        },
        {
            onSuccess: (data) => {
                // console.log(data);
            }
        }
    );

    const removeItemFromList = (jobId) => {
        queryClient.setQueryData(["benefits_list"], oldData => ({
            ...oldData,
            results: oldData.results.filter(job => job.id !== jobId)
        }));
    };

    const addItem = (job) => {
        queryClient.setQueryData(["benefits_list"], oldData => ({
            ...oldData,
            results: oldData.append(0,job)
        }));
    };

    return { data, isLoading, isError, removeItemFromList, refetch, };
}

export default UseBenefitList;