import React from 'react'
import { Link } from 'react-router-dom'

function MainMenu() {
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="../index3.html" className="brand-link">
                <img src="/assets/images/logo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" />
                <span className="brand-text font-weight-light">Job Flow Connect</span>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="/assets/images/user.png" className="img-circle elevation-2" alt="User Image" />
                    </div>
                    <div className="info">
                        <a href="#" className="d-block">Super Admin</a>
                    </div>
                </div>

                <div className="form-inline">
                    <div className="input-group" data-widget="sidebar-search">
                        <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                        <div className="input-group-append">
                            <button className="btn btn-sidebar">
                                <i className="fas fa-search fa-fw"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        <li className="nav-item">
                            <Link to="/cms/dashboard" className="nav-link ">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                </p>
                            </Link>
                        </li>
                        {/* <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>
                                    Dashboard
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <a href="../index.html" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Dashboard v1</p>
                                    </a>
                                </li>
                                
                            </ul>
                        </li> */}
                        <li className="nav-item">
                            <Link to="/cms/job" className="nav-link ">
                                <i className="nav-icon fa fa-shopping-bag"></i>
                                <p>
                                    Jobs
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/cms/job/" className="nav-link ">
                                        <i className="fa fa-list nav-icon"></i>
                                        <p>Jobs List</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/cms/job/benefit" className="nav-link ">
                                        <i className="fa fa-tag nav-icon"></i>
                                        <p>Benefits</p>
                                    </Link>
                                </li>
                                

                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/cms/user" className="nav-link ">
                                <i className="nav-icon fa fa-user-circle"></i>
                                <p>
                                    Users
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </Link>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <Link to="/cms/user/employee" className="nav-link ">
                                        <i className="far fa-user nav-icon"></i>
                                        <p>Employees</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/cms/user/employer" className="nav-link ">
                                        <i className="far fa-user nav-icon"></i>
                                        <p>Employers</p>
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/cms/user/recruiters" className="nav-link ">
                                        <i className="far fa-user nav-icon"></i>
                                        <p>Recruiters</p>
                                    </Link>
                                </li>

                            </ul>
                        </li>

                        <li className="nav-item">
                            <Link to="/cms/user" className="nav-link ">
                                <i className="nav-icon fa fa-file-alt"></i>
                                <p>
                                    Applications
                                </p>
                            </Link>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link">
                                <i className="nav-icon fas fa-cog"></i>
                                <p>
                                    Settings
                                    <i className="right fas fa-angle-left"></i>
                                </p>
                            </a>
                            <ul className="nav nav-treeview">
                                <li className="nav-item">
                                    <a href="/cms/language" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Languages</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/cms/translations" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Translations</p>
                                    </a>
                                </li>

                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href="/cms/login/?signout=1" className="nav-link ">
                                <i className="nav-icon fa fa-sign-out"></i>
                                <p>
                                    Sign out
                                </p>
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default MainMenu