import React, { useState } from 'react'
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { BaseHttpService } from 'cms/core/services';
import { Utils } from 'core/utils';
import DeleteDialog from 'core/components/shared/DeleteDialog';
import CustomModal from 'cms/core/shared/dialogs/CustomModal';
import TranslationForm from '../components/TranslationForm';
import SearchQuery from 'cms/components/forms/SearchQuery';
import AdminLayout from 'cms/core/shared/AdminLayout';

function TranslationsPage() {
  const [translations, setData] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [query, setQuery] = useState("");

  const { isLoading, error, data, refetch } = useQuery(['translations', query], () => { return BaseHttpService.searchTranslations(query) }, {
    onSuccess: (data) => {
      setData(data.results)
    },
    // cacheTime: 60*60*24*30*1000// Cache results for 30 days
    refetchInterval: 60 * 10000,
  });

  const [selectedId, setSelectedId] = useState(0);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);



  const _handleEdit = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setEditModalIsOpen(true)
  }

  const _handleDelete = (e, id) => {
    setSelectedId(id);
    setRefresh(refresh + 1)
    setDeleteDialogIsOpen(true)
  }

  const _onDeleteDone = () => {
    var temp = translations.filter(item => item.id !== selectedId)
    setData(temp)
    setDeleteDialogIsOpen(false);
    setRefresh(refresh + 1)
  }
  return (
    <AdminLayout>
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h5>Translations</h5>
            </div>
            <div className="col-sm-6">
             
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item"><a href="/cms">Home</a></li>
                <li className="breadcrumb-item active">Translations</li>
              </ol>
              <div className="btns d-flex float-sm-right mr-3">
                <SearchQuery onQuery={(query) => { setQuery(query); refetch(); }} query={query} />

                {/* <Link to="/cms/category/" className="btn btn-icon bg-warning mr-2" title="categories"><i className="fa fa-folder "></i></Link> */}
                <button className="btn btn-icon bg-success" onClick={() => { setSelectedId(null); setEditModalIsOpen(true) }}><i className="fa fa-plus "></i></button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <table className="table table-striped">
            <thead>
              <tr>
                <th>
                  #
                </th>
                <th>Key</th>
                <th>Value</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {translations && translations.map(item => (
                <tr key={"trans" + item.id}>
                  <td>{item.id}</td>
                  <td className='kfont'><Link onClick={(e) => { _handleEdit(e, item.id) }}>{item.key}</Link></td>
                  <td className='kfont'>{Utils.getDefaultValue(item,2)}</td>
                  <td className='text-right'>
                    <a href="#" onClick={(e) => { _handleDelete(e, item.id) }} className="btn btn-outline btn-swap-1">
                      <span><i className="fas fa-trash color-gray "></i></span>
                    </a>

                  </td>

                </tr>
              ))}


            </tbody>


          </table>
        </div>
      </section>
      <CustomModal big key={editModalIsOpen} setOpen={(value) => setEditModalIsOpen(value)}
        open={editModalIsOpen} >
        <TranslationForm id={selectedId} onEditDone={() => { refetch(); setEditModalIsOpen(false) }} />

      </CustomModal>

      <DeleteDialog
        url={`/api/translate/${selectedId}`}
        isOpen={deleteDialogIsOpen}
        key={"mdl" + deleteDialogIsOpen + selectedId}
        setOpen={setDeleteDialogIsOpen}
        notifyDone={_onDeleteDone}
      />
    </AdminLayout>
  )
}

export default TranslationsPage