import React from 'react'

function Footer() {
    return (
        <>
            <aside className="control-sidebar control-sidebar-dark">
            </aside>
            <footer className="main-footer">
                <div className="float-right d-none d-sm-block">
                    <b>Version</b> 1.0.0
                </div>
                <strong>Copyright &copy; 2024-2025 <a href="https://jobflowconnect.com">JobFlowConnect.com</a>.</strong> All rights reserved.
            </footer>
        </>

    )
}

export default Footer