import { UserContextProvider } from 'core/context/UserContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import MainRoutes from 'routes/MainRoutes';


const queryClient = new QueryClient()

function App() {

  return (
    <BrowserRouter basename="/">
      <UserContextProvider>
        <QueryClientProvider client={queryClient}>
          <MainRoutes />
        </QueryClientProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
