import React from 'react'
import { Utils } from 'core/utils';
import { Link, useParams } from 'react-router-dom';
import DeleteDialog from 'core/components/shared/DeleteDialog';
import UseBenefitList from '../hooks/UseBenefitList';
import CustomModal from 'cms/core/shared/dialogs/CustomModal';
import BenefitForm from './forms/BenefitForm';

function BenefitList() {
    const { data, isLoading, isError, removeItemFromList,refetch } = UseBenefitList();
    const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
    const [addDialogIsOpen, setAddDialogIsOpen] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
    const [selectedItem, setSelectedItem] = React.useState(0);
    const [refresh, setRefresh] = React.useState(0);
    const { id } = useParams();


    const _handleDelete = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setDeleteDialogIsOpen(true)
    }

    const _onDeleteDone = (id) => {
        setDeleteDialogIsOpen(false);
        removeItemFromList(selectedId)
        // setRefresh(refresh + 1)
    }

    const _handleEdit = (e, id) => {
        setSelectedId(id);
        setRefresh(refresh + 1)
        setAddDialogIsOpen(true)
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title"></h3>

                        <div className="card-tools">
                            <div className="input-group input-group-sm" style={{ width: "350px" }}>
                                <input type="text" name="table_search" className="form-control float-right" placeholder="Search" />
                                <div className="input-group-append">
                                    <button type="submit" className="btn btn-default">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>

                            </div>
                            <button type="submit" className="btn btn-primary" onClick={(e) => { e.stopPropagation(); setSelectedId(null); setAddDialogIsOpen(true) }}>
                                <i className="fas fa-plus"></i>
                            </button>
                        </div>
                    </div>
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Title</th>
                                    <th>Status</th>
                                    <th>Created at</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.results.map((item) => (
                                    <tr key={item.id + "item"}>
                                        <td>{item.id}</td>
                                        <td>{item.title}</td>
                                        <td>{item.status} <span className="tag tag-success">Approved</span></td>
                                        <td>{Utils.getDate(item.created_at)}</td>
                                        <td>
                                            <div className="btns">
                                                <Link href="#edit" onClick={(e) => { _handleEdit(e,item.id) }}  >
                                                    <i className="fa fa-edit"></i>
                                                </Link>
                                                <button className="btn" onClick={(e) => { e.stopPropagation(); _handleDelete(e, item.id) }}>
                                                    <i className="fa fa-trash text-danger"></i>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                                )}


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <DeleteDialog
                url={`/job/benefit/${selectedId}`}
                isOpen={deleteDialogIsOpen}
                key={"mdl" + deleteDialogIsOpen + selectedId}
                setOpen={setDeleteDialogIsOpen}
                notifyDone={_onDeleteDone}
            />

            <CustomModal
                big className="" key={addDialogIsOpen} setOpen={(value) => setAddDialogIsOpen(value)}
                open={addDialogIsOpen} title={selectedId ? "Edit benefit" : "Add new benefit"}
            >
                <BenefitForm id={selectedId} onEditDone={(item)=>{  refetch()}} />
            </CustomModal>

        </div>
    )
}

export default BenefitList